import React from 'react';
import ThreeColumnLayout from '../components/Layouts/ThreeColumnLayout';
import PageBackground from '../components/Layouts/PageBackground';
import { web3integration } from '../data';
const Web3Integration = () => {
  return (
    <div>
      {
        <PageBackground
          title="Web3 Integration"
          description="Play poker with your NFT for the first time ever."
        >
          <ThreeColumnLayout data={web3integration} style />
        </PageBackground>
      }
    </div>
  );
};

export default Web3Integration;
