import './Navbar.css';
import React, { useState, useEffect } from 'react';
import SopoLogo from '../assets/icons/sopo-logo.png';
import CertLogo from '../assets/icons/cert-logo.png';
import PlayGame from '../assets/icons/play-game.png';
import Hamburger from '../assets/icons/hamburger.png';
import { useNavigate } from 'react-router-dom';
import Pdf from '../assets/cfg.pdf';

export default function Navbar() {
  const navigates = useNavigate();
  const [toggleMenu, setToggleMenu] = useState(false);

  const toggleNav = () => {
    setToggleMenu(!toggleMenu);
  };
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', changeWidth);
    return () => {
      window.removeEventListener('resize', changeWidth);
    };
  }, []);

  const navigate = (param) => {
    setToggleMenu(false);
    navigates(param);
  };

  const DesktopNavbar = () => {
    return (
      <>
        <div className="left-align-div">
          <ul className="list">
            <li className="items logo">
              <img
                src={SopoLogo}
                style={{ maxWidth: '74px' }}
              />
            </li>
            <li className="items" onClick={() => navigate('/')}>
              Home
            </li>
            <li className="items" onClick={() => navigate('howtoplay')}>
              How To Play
            </li>
            <li className="items" onClick={() => navigate('web3integration')}>
              Web3 Integrations
            </li>
            <li className="items" onClick={() => navigate('certifiedfair')}>
              Certified Fair
            </li>
            <li className="items" onClick={() => navigate('socialfeatures')}>
              Social Features
            </li>
          </ul>
        </div>
        <div className="right-align-div">
          <ul className="list">
            <li
              className="items play-now-btn"
              onClick={() => window.open('https://app.playsopo.com/link/open')}
            >
              Play Now
            </li>
            <li className="items mr-0">
              <img
                src={CertLogo}
                style={{ maxWidth: '61px' }}
                onClick={() => window.open(Pdf)}
              />
            </li>
          </ul>
        </div>
      </>
    );
  };
  const MobileNavbar = () => {
    return (
      <>
        <div className="mobile-top-bar gradient-red-bg">
          <div className="m-li-div j-start">
            <li className="items-mobile" onClick={toggleNav}>
              <img src={Hamburger} style={{ maxWidth: '31px' }} />
            </li>
          </div>
          <div className="m-li-div">
            <li className="items-mobile">
              <img
                style={{ maxHeight: '45px' }}
                src={SopoLogo}
                onClick={() => window.open('https://playsopo.com/')}
              />
            </li>
          </div>
          <div className="m-li-div j-end">
            <li className="items-mobile">
              <img
                style={{ maxHeight: '37px',marginRight:"5px" }}
                src={PlayGame}
                onClick={() =>
                  window.open('https://app.playsopo.com/link/open')
                }
              />
            </li>
            <li className="items-mobile">
              <img
                src={CertLogo}
                style={{ maxWidth: '37px' }}
                onClick={() => window.open(Pdf)}
              />
            </li>
          </div>

        </div>
        {toggleMenu && (
          <div
            className={`gradient-red-bg mobile-nav-container ${
              toggleMenu && 'toggleMenu'
            } `}
          >
            <ul>
              <li className="items py-10" onClick={() => navigate('/')}>
                Home
              </li>
              <li className="items py-10" onClick={() => navigate('howtoplay')}>
                How To Play
              </li>
              <li
                className="items py-10"
                onClick={() => navigate('web3integration')}
              >
                Web3 Integrations
              </li>
              <li
                className="items py-10"
                onClick={() => navigate('certifiedfair')}
              >
                Certified Fair
              </li>
              <li
                className="items py-10"
                onClick={() => navigate('socialfeatures')}
              >
                Social Features
              </li>
              <li
                className="items py-10"
                onClick={() => navigate('b2b-solution')}
              >
                B2B Solutions
              </li>
              <li
                className="items py-10"
                onClick={() => navigate('terms-of-service')}
              >
                Terms of Service
              </li>
              <li
                className="items py-10"
                onClick={() => navigate('privacy-policy')}
              >
                Privacy Policy
              </li>
              <li
                className="items py-10"
                onClick={() => navigate('contact-us')}
              >
                Contact Us
              </li>
            </ul>
          </div>
        )}
      </>
    );
  };
  return (
    <>
      {screenWidth > 1025 ? (
        <nav className="gradient-red-bg">
          <div className="wide-container d-flex j-center nav-inner">
            <DesktopNavbar />
          </div>
        </nav>
      ) : (
        <MobileNavbar />
      )}
    </>
  );
}
