import React, { useEffect, useState } from 'react';
import ThreeColumnLayout from '../components/Layouts/ThreeColumnLayout';
import PageBackground from '../components/Layouts/PageBackground';
import { certifiedfair } from '../data';
import MobileCerfifiedLayout from '../components/Layouts/MobileCertifiedLayout';
import Pdf from "../assets/cfg.pdf"

const CertifiedFair = () => {
  const [mobile, setmobile] = useState(false);
  useEffect(() => {
    if (window.innerWidth < 973) {
      setmobile(true);
    }
  }, []);
  return (
    <div>
      {
        <PageBackground
          title="Certified Fair"
          description="SOPO is committed to the highest possible standard of safe and secure gameplay."
        >
          {mobile ? (
            <MobileCerfifiedLayout data={certifiedfair} />
          ) : (
            <ThreeColumnLayout data={certifiedfair} mobileview style certifClick={()=>window.open(Pdf)} />
          )}
        </PageBackground>
      }
    </div>
  );
};

export default CertifiedFair;
