import './ThreeColumn.css';
import pdf from "../../assets/cfg.pdf"

const MobileCerfifiedLayout = ({ data }) => {
  return (
    <>
      <div className="container">
        <div className="column-data">
          <img src={data[1].image} onClick={() => window.open(pdf)} className={'card'}  style={{maxHeight:"165px"}}/>
          <div className="column-description" >{data[1].description}</div>
        </div>
        <div className="bottom-container">
          <div className="column-mobile-image">
            <img src={data[0].image} />
            <img src={data[2].image} />
          </div>
          <div className="column-mobile-description">
            <div className="column-description">{data[0].description}</div>
            <div className="column-description">{data[2].description}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileCerfifiedLayout;
