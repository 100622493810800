import './PageBackground.css';
import SOPOLogo from '../../assets/images/sopoLogo.png';
const PageBackground = ({ children, title, description }) => {
  return (
    <div className="background">
      <div className="wide-container" style={{ position: 'relative' }}>
        <img src={SOPOLogo} className="page-sopo-logo" />
        <div className="p-relative background-inner-container">
          <h3 className="page-title">{title}</h3>
          {description && <div className="page-description">{description}</div>}
          {children}
        </div>
      </div>
    </div>
  );
};

export default PageBackground;
