import how1 from './assets/images/howtoplay/how_1.png';
import how2 from './assets/images/howtoplay/how_2.png';
import how3 from './assets/images/howtoplay/how_3.png';
import titleimage from './assets/images/howtoplay/circle.svg';
import learnMore from './assets/images/howtoplay/learn-more.svg'

import web1 from './assets/images/web3integration/web1.png';
import web2 from './assets/images/web3integration/web2.png';
import web3 from './assets/images/web3integration/web3.png';

import certified1 from './assets/images/certifiedfair/certified1.png';
import certified2 from './assets/images/certifiedfair/certified2.png';
import certified3 from './assets/images/certifiedfair/certified3.png';

import social1 from './assets/images/socialfeatures/social1.png';
import social2 from './assets/images/socialfeatures/social2.png';
import social3 from './assets/images/socialfeatures/social3.png';

export const howtoplay = [
  {
    image: how1,
    title: 'Quickplay',
    description:
      'No account required. Just generate a table on the fly and share your link with who you want to play with!',
  },
  {
    image: how2,
    title: 'Clubs',
    titleimage: titleimage,
    secondTitleImage: learnMore,
    description:
      'Create a club and play with friends. All the club control options you’re used to, and MORE! Plus, SOPO is a completely diamond free. No more pesky fees for club owners!',
  },
  {
    image: how3,
    title: 'Freeplay',
    description:
      'Play against bots and earn gold to spend in the shop on avatars, time banks, emotes, and more. Play both game modes, NLH or Jackpot, and test your skills!',
  },
];
export const web3integration = [
  {
    image: web1,
    description:
      'Safely, & securely link your Metamask using the NFT aggregator we built ourselves, in-house, from the ground up.',
  },
  {
    image: web2,
    description:
      "Access your entire NFT gallery and select which you'd like to play with. Even choose a few as your favorites!",
  },
  {
    image: web3,
    description:
      'Play against your friends using your favorite PFP. Clicking on an avatar even shows all metadata!',
  },
];
export const certifiedfair = [
  {
    image: certified1,
    description:
      'Our in-house security team has built in an IP restriction feature to ensure that gameplay is as fair as possible.',
  },
  {
    image: certified2,
    openpdf:true,
    description:
      'We received our CFG Certification, which is viewable by clicking the symbol above.',
  },
  {
    image: certified3,
    description:
      'SOPO requires all users to turn on location services to abide by the in-app GPS restrictions.',
  },
];

export const socialfeatures = [
  {
    image: social1,
  },
  {
    image: social2,
  },
  {
    image: social3,
  },
];
