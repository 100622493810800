import './Footer.css';
import OdysseusLogo from '../assets/icons/odysseus-logo.png';
import Sopo from '../assets/icons/sopoHeader.png';
import { useNavigate } from "react-router-dom"; 

const Footer = () => {
  const navigates = useNavigate();
  const navigate = (param) =>{
    navigates(param);
  }
  return (
    <div className="footer gradient-red-bg">
      <div className="footer-inner-container footer-desktop">
        <div className='f'>
          <ul>
            <li className="footer-li" onClick={()=>navigate("/")}>Home</li>
            <li className="footer-li" onClick={()=>navigate("howtoplay")}>How To Play</li>
            <li className="footer-li" onClick={()=>navigate("web3integration")}>Web3 Integrations</li>
            <li className="footer-li" onClick={()=>navigate("certifiedfair")}>Certified Fair</li>
            <li className="footer-li" onClick={()=>navigate("socialfeatures")}>Social Features</li>
          </ul>
        </div>
        <div className='footer-logos'>
            <div>
          <img src={Sopo} style={{ height: '80px' }} /></div>
          <div>
          <img src={OdysseusLogo} style={{ height: '90px' }} />
          </div>
        </div>
        <div className='f'>
          <ul>
            <li className="footer-li" onClick={()=>navigate("b2b-solution")}>B2B Solutions</li>
            <li className="footer-li" onClick={()=>navigate("terms-of-service")}>Terms of Service</li>
            <li className="footer-li" onClick={()=>navigate("privacy-policy")}>Privacy Policy</li>
            <li className="footer-li" onClick={()=>navigate("contact-us")}>Contact Us</li>
          </ul>
        </div>
      </div>
      <div className="footer-inner-container footer-mobile">
        <div className='f'>
        <img src={OdysseusLogo} style={{ height: '60px' }} />
          <ul>
            <li className="footer-li" onClick={()=>navigate("b2b-solution")}>B2B Solutions</li>
            <li className="footer-li" onClick={()=>navigate("terms-of-service")}>Terms of Service</li>
            <li className="footer-li" onClick={()=>navigate("privacy-policy")}>Privacy Policy</li>
            <li className="footer-li" onClick={()=>navigate("contact-us")}>Contact Us</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Footer;
