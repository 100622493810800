import './SocialLayout.css';
import ThreeColumn from './ThreeColumn';

const SocialLayout = ({ data, flex }) => {
  console.log(data);
  return (
    <div className="container">
      <div className="social-row">
        <ThreeColumn content={data[0]} flex={flex} />
        <div className="center-box">
          <p>
            At SOPO, we understand that multimedia integration isn't just what
            people want, its what they need. SOPO uses groundbreaking tech to
            get you breaking news, the hottest trending videos, live streams,
            and more to make you feel right at home while you play.
          </p>
          <br />
          <p>
            Thats why we have dedicated back-end systems to facilitate all of
            this based on platform specific data to keep you up to date, and
            entertained.
          </p>
          <br />
          <div className="center-bottom-box">
            <p>
              All of this is available, not only on desktop, but also on mobile.
              Additionally, you can access all of this while you're sitting at a
              table, or multiple tables!
            </p>
            <img src={data[1].image} alt="computer" />
          </div>
        </div>
        <ThreeColumn content={data[2]} flex={flex} />
      </div>
    </div>
  );
};

export default SocialLayout;
