import './ThreeColumnLayout.css';
import ThreeColumn from './ThreeColumn';
 
const ThreeColumnLayout = ({ data, linebackground, style, mobileview, infoClick,certifClick }) => {
  console.log(data);
  return (
    <div className="container">
      <div className="row">
        {data.map((how) => (
          <ThreeColumn
            content={how}
            linebackground={linebackground}
            style={style}
            mobileview={mobileview}
            infoClick={infoClick}
            certifClick={certifClick}
          />
        ))}
      </div>
    </div>
  );
};

export default ThreeColumnLayout;
