import './ThreeColumn.css';
import pokerline from '../../assets/images/howtoplay/line.svg';

const ThreeColumn = ({
  content,
  linebackground,
  style,
  mobileview,
  flex,
  infoClick,
  certifClick
}) => {
  return (
    <>
      <div
        className={linebackground ? 'column-background' : 'column'}
        style={{
          margin: mobileview ? 'unset' : '',
          flex: flex ? '16%' : '16%%',
        }}
      >
        <div className="column-content">
          <div className="title-box">
            <div className="column-title">{content.title}</div>
            {content.titleimage && (
              <div onClick={infoClick} className='info-click-box'>
                <img src={content.titleimage} className='i-icon' alt=''/>
                {content.secondTitleImage && <img className='learn-more-icon' src={content.secondTitleImage} alt='' />}
              </div>
            )}
          </div>
          {linebackground && <img src={pokerline} className="line" />}
          {mobileview ? (
            <div style={{ height: '315px' }}>
              <img
                onClick={content.openpdf && certifClick}
                src={content.image}
                className={`card ${content.openpdf ? "hoverPointer" : ""}`}
                style={{ maxWidth: linebackground ? '60%' : '' }}
              />
            </div>
          ) : (
            <img
              src={content.image}
              className={style ? 'card-style' : 'card'}
              style={{ maxWidth: linebackground ? '60%' : '' }}
            />
          )}
          <div className="column-description">{content.description}</div>
        </div>
      </div>
    </>
  );
};

export default ThreeColumn;
