import React from 'react';
import ThreeColumnLayout from '../components/Layouts/ThreeColumnLayout';
import PageBackground from '../components/Layouts/PageBackground';
import { howtoplay } from '../data';
import Carousel from '../components/Carousel';
import Modal from 'react-modal';
import Close from "../assets/images/howtoplay/carousel/close.png"

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    padding: "0px!important",
    transform: 'translate(-50%, -50%)',
  },
};
const HowtoPlay = () => {
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }
  return (
    <div>
      {
        <PageBackground title="How To Play">
          <ThreeColumnLayout data={howtoplay} linebackground infoClick={openModal}/>
          <div>

          
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStyles}
          >
            <Carousel />
            <button className='closeButton' onClick={closeModal}><img src={Close}/></button>
          </Modal>
          </div>
        </PageBackground>
      }
    </div>
  );
};

export default HowtoPlay;
