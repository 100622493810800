import React, { useEffect, useState } from 'react';
import SocialLayout from '../components/Layouts/SocialLayout';
import PageBackground from '../components/Layouts/PageBackground';
import { socialfeatures } from '../data';
import MobileSocialLayout from '../components/Layouts/MobileSocialLayout';
const SocialFeatures = () => {
  const [mobile, setmobile] = useState(false);
  useEffect(() => {
    if (window.innerWidth < 899) {
      setmobile(true);
    }
  }, []);
  return (
    <div>
      {
        <PageBackground
          title="Social Features"
          description="SOPO is unlike any poker app you've seen before, with social features that break the mold."
        >
          {mobile ? (
            <MobileSocialLayout data={socialfeatures} flex />
          ) : (
            <SocialLayout data={socialfeatures} flex />
          )}
        </PageBackground>
      }
    </div>
  );
};

export default SocialFeatures;
