import React from 'react';
import './HomePage.css';
import Column from '../components/Layouts/Column';
import SopoLogo from '../assets/images/sopoLogo.png';
import Both from '../assets/images/both.png';
import ThreeColumnLayout from '../components/Layouts/ThreeColumnLayout';
import PageBackground from '../components/Layouts/PageBackground';
import AppStore from '../assets/icons/appStore.png';
import GoogleStore from '../assets/icons/googleStore.png';
import SopoVideo from '../assets/sopoVideo.mp4';
const HomePage = () => {
  return (
    <div className="homepage-container">
      <div className="row homepage-row">
        <Column>
          <div className='home-row'>
            <div
              style={{ maxWidth: '80%', margin: '0 auto' }}
              className="text-left home-col"
            >
              <p>Completely free.</p>
              <p>Certified Fair.</p>
              <p>Web3 Integrated.</p>
              <p>Ready for the future.</p>
            </div>
            <div className="d-flex download-btns home-col">
              <div style={{cursor:"pointer"}} onClick={()=>window.open("https://apps.apple.com/us/app/sopo-social-poker/id1441432679")}>
                <img src={AppStore} />
              </div>
              <div style={{cursor:"pointer"}} onClick={()=>window.open("https://play.google.com/store/apps/details?id=software.odysseus.sopo&hl=en&gl=US")}>
                <img src={GoogleStore} />
              </div>
            </div>
          </div>
        </Column>
        <Column>
          <div>
            <img src={SopoLogo} />
            <p>
              Poker's <span style={{ color: '#FFD500' }}>next step</span>
            </p>
          </div>
          <div className="play-now-btn play-now-btn-home" onClick={()=>window.open("https://app.playsopo.com/link/open")}> Play now</div>
        </Column>
        <Column>
          <div>
            <img src={Both}  />
          </div>
        </Column>
      </div>
      <div style={{maxWidth:"800px", margin:"0 auto", padding:"40px 20px"}}>
      <video style={{width:"100%"}} controls autoPlay muted> 
        <source src={SopoVideo} type="video/mp4"/>
      </video>
      </div>
      
      {/* 
        <PageBackground title="How To Play">
          
        </PageBackground> */}
    </div>
  );
};

export default HomePage;
