import React from 'react'
import ReactModal from 'react-modal'

const ContactModal = ({isOpen,children }) => {
  return (
    <ReactModal ariaHideApp={false} isOpen={isOpen} >
        {children}
    </ReactModal>
  )
}

export default ContactModal